import React, { Fragment, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import { ConfigType } from '../Main'
import { Button } from '../components/Button'
import { S3_BUCKET_URL } from '../constants'

type Props = {
  data: ConfigType
  navigate: (value: number) => void
  gotoPage: number
}

const default_list = ['Ingen gevinst', 'Stor popcorn', '3', '4', '5', '6', '7', '8']

const OFFSET = 0

const fieldOne = '#630014'
const fieldTwo = '#f1281e'

export const SpinPage = ({ data, navigate, gotoPage }: Props) => {
  const [spinCompleted, setSpinCompleted] = useState(false)
  const [acc, setAcc] = useState(0)
  const clickker = () => {
    navigate(gotoPage)
  }

  const spinDone = () => {
    console.log('SpinPage > COMPLETED = ')
    setSpinCompleted(true)
  }

  const [props, set] = useSpring(() => ({
    transform: 'rotate(0deg)',
    immediate: false,
    onRest: (e) => {
      console.log('Wheel > onRest = ', e)
      if (e.finished === true) {
        console.log('SpinPage > done = ')
        setSpinCompleted(true)
      }
    },
  }))
  const config = { mass: 70, tension: 200, friction: 200, precision: 0.0001 }
  const spinWheel = () => {
    const offset = Math.round(Math.random() * (8 - 4) + 4) * 360
    const k = acc + offset

    set({
      from: { transform: `rotate(${acc}deg)` },
      //to: { transform: `rotate(${360 * offset}deg)` },
      transform: `rotate(${k}deg)`,
      immediate: false,
      config,
    })

    setAcc(k)
    //setAcc(90)
  }

  return (
    <>
      <div className="row h-100 d-flex flex-column justify-content-between justify-content-md-start  ">
        {/*<div className="row ">*/}
        <Wheel data={data} props={props} set={set} setSpinCompleted={spinDone} spinCompleted={spinCompleted}></Wheel>

        {spinCompleted ? (
          <div className="col-12 col-md-8 mx-auto pb-6 mt-3 mt-md-6">
            <p className="text-white text-center fw-bold">{data.spinPage.winHeadline}</p>
            <p className="text-white text-center mt-md-7">{data.spinPage.winBody}</p>
            <div className="d-flex justify-content-center mt-3">
              <Button
                onClick={clickker}
                style={{ background: data.wheel.secondaryColor }}
                label={data.spinPage.winBtn}
              ></Button>
            </div>
          </div>
        ) : null}

        {!spinCompleted ? (
          <div className="col-12 d-flex justify-content-center mt-4 mb-5">
            <Button onClick={spinWheel} label={data.spinPage.spinBtn}></Button>
          </div>
        ) : null}
      </div>
    </>
  )
}

const generateWedgeString = function (startX, startY, startAngle, endAngle, radius) {
  var x1 = startX + radius * Math.cos((Math.PI * startAngle) / 180)
  var y1 = startY + radius * Math.sin((Math.PI * startAngle) / 180)
  var x2 = startX + radius * Math.cos((Math.PI * endAngle) / 180)
  var y2 = startY + radius * Math.sin((Math.PI * endAngle) / 180)

  var pathString =
    'M' + startX + ' ' + startY + ' L' + x1 + ' ' + y1 + ' A' + radius + ' ' + radius + ' 0 0 1 ' + x2 + ' ' + y2 + ' z'

  return pathString
}

const Wheel = ({ data, setSpinCompleted, spinCompleted, props, set }) => {
  const r = 240
  const cx = 250
  const cy = 250
  const wheelStrokeWidth = 10
  const wheelStroke = 'none' //'#ffdd00'
  const fullW = cx * 2

  const list = default_list

  const wofImage = data.wofImage ? S3_BUCKET_URL + '/' + data.wofImage : ''

  const rederItems = (numOfItems) => {
    let items: JSX.Element[] = []
    for (let i = 0; i < numOfItems; i++) {
      //let txLength = Math.cos(2 * Math.PI * ((i + 0.5) / numOfItems + OFFSET)) * (r / 2.5)
      //let tyLength = Math.sin(2 * Math.PI * ((i + 0.5) / numOfItems + OFFSET)) * (r / 2.5)

      const rotation = (i / numOfItems + OFFSET) * 360

      // how many degrees is each slice
      const sliceSize = Math.round(360 / numOfItems)

      items.push(
        <Fragment key={i}>
          {i % 2 ? (
            <path
              d={generateWedgeString(cx, cy, rotation, rotation + sliceSize, r - wheelStrokeWidth / 2)}
              fill={fieldTwo}
            />
          ) : null}
        </Fragment>
      )
    }
    return items
  }

  const renderedItems = rederItems(list.length)

  return (
    <>
      <div className="col-12 px-4 col-md-8 mx-auto d-flex justify-content-center position-relative">
        <div className="h-100 w-100 position-relative">
          {spinCompleted ? (
            <div
              className="position-absolute h-100 w-100"
              style={{ background: 'yello', top: -65, left: 0, zIndex: -1 }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${fullW} ${fullW}`}>
                <defs>
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(90)">
                    <stop offset="10%" stopColor="#ffffff" stopOpacity="0" />
                    <stop offset="100%" stopColor="#ffffff" />
                  </linearGradient>
                </defs>

                <path fill="url(#grad1)" d={generateWedgeString(250, 250 + 50, -118, -64, r + 100)} />
              </svg>
            </div>
          ) : null}

          <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${fullW} ${fullW}`}>
            <g fill={fieldOne} stroke={wheelStroke} strokeWidth={wheelStrokeWidth}>
              <circle cx={cx} cy={cy} r={r} />
            </g>
            <animated.g
              style={{
                transform: props.transform,
                transformOrigin: 'center',
              }}
            >
              {renderedItems}
              <image href={wofImage} height="100%" width="100%" opacity={1} />
            </animated.g>
            {/*<g fill="#61DAFB">
              <circle cx="250" cy="250" r="15" />
            </g>
            <g fill="black">
              <circle cx="250" cy="250" r="5" />
            </g>*/}
          </svg>
        </div>
      </div>
    </>
  )
}
