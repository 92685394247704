export const BASE_URL = process.env.REACT_APP_BASE_URL
export const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL
export const PROJECT_REF = process.env.REACT_APP_PROJECT_REF

export const DEBUG: boolean = process.env.REACT_APP_DEBUG === 'true' ? true : false

//
// For debiugging
//
//export const DEBUG_PROJECTREF: string = '65cfa93546e9053703abdaac'
//export const DEBUG_PROJECTREF: string = '65cfedf1ee8dbc8cc3102ab8'
export const DEBUG_PROJECTREF: string = '67bcea8e865b2c3ea51ec7ad'

export const DEFAULT_BGIMAGE =
  'https://images.unsplash.com/photo-1517245386807-9b4d0dd8eac1?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'
