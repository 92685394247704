import React, { useEffect, useState } from 'react'
import { ConfigType } from '../Main'
import { Button } from '../components/Button'
import { ReactComponent as CheckMark } from '../assets/checkmark.svg'
import './form.scss'
import { isValidEmail } from '../util/isValidEmail'
import { ENDPOINTS } from '../service/ENDPOINTS'
import { post } from '../service/API'
import Dialog from 'rc-dialog/es/Dialog'
import './modal.scss'
import { DEBUG, DEBUG_PROJECTREF, PROJECT_REF } from '../constants'

type Props = {
  data: ConfigType
  navigate: (value: number) => void
  gotoPage: number
}

const errorColor = '#ff622f'

export const RegistrationPage = ({ data, navigate, gotoPage }: Props) => {
  const goToPage = (form) => {
    navigate(gotoPage)
  }

  return (
    <div className="row h-100 d-flex flex-column justify-content-between justify-content-md-start  ">
      <div className="col-12"></div>

      <div className="col-12 d-flex justify-content-center">
        <div className="w-100">
          <h2 className="text-center">{data.registration.headline}</h2>
          <p className="text-center px-4  p-small">{data.registration.description}</p>
          <RegistrationForm label="Navn" data={data} goToPage={goToPage}></RegistrationForm>
        </div>
      </div>

      <div className="col-12 d-flex justify-content-center mt-3 pb-5"></div>
    </div>
  )
}

type FormType = {
  name: string
  lastname: string
  email: string
  phone: string
  terms: boolean
  privacy: boolean
  dropdown: null | string
  questionniare: any[]
}

type FormErrorType = {
  name: boolean
  lastname: boolean
  email: boolean
  phone: boolean
  terms: boolean
  privacy: boolean
  dropdown: boolean

  questionniare: boolean
}

const filedShadow = '0px 0px 0px 1px rgba(255, 255, 255, .7)'
const fontSize = '1rem'

const RegistrationForm = ({ label, data, goToPage }) => {
  const registration = data.registration
  const questionniare = data.questionnaire

  const [showModal, setShowModal] = useState<boolean>(false)
  const [dropDownSeleted, setDropDownSeleted] = useState<any>('')
  const [questionniareSeleted, setQuestionniareSeleted] = useState<any[]>(questionniare.map(() => ''))

  const usePhoneForm = data?.registration?.formPhone ? true : false

  const [state, setState] = useState<FormType>({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    terms: false,
    privacy: false,
    dropdown: null,
    questionniare: [],
  })
  const [error, setErrors] = useState<FormErrorType>({
    name: false,
    lastname: false,
    email: false,
    phone: false,
    terms: false,
    privacy: false,
    dropdown: false,
    questionniare: false,
  })

  const setQuestionniareProxy = (value) => {
    setQuestionniareSeleted(value)
    setErrors({ ...error, questionniare: false })
  }
  const onChange = (e) => {
    const key = e.target.name

    if (key && error[key] === true) {
      setErrors({ ...error, [key]: false })
    }

    setState({ ...state, [e.target.name]: e.target.value })
  }

  const validateForm = () => {
    const errorCopy = { ...error }
    const validEmail = isValidEmail(state.email)

    errorCopy.email = !validEmail

    if (usePhoneForm) {
      errorCopy.phone = state.phone.length !== 8
    }

    const checkAnswers = questionniareSeleted.filter((answer) => (answer === '' ? false : true))

    console.log(' ')
    console.log('RegistrationPage > questionniareSeleted = ', questionniareSeleted)
    console.log('RegistrationPage >  CHECK = ', questionniare, checkAnswers)

    errorCopy.questionniare = questionniare.length === checkAnswers.length ? false : true
    console.log('RegistrationPage > STATTUS = ', errorCopy.questionniare)

    errorCopy.name = state.name.length < 2
    errorCopy.lastname = state.lastname.length < 2

    errorCopy.terms = !state.terms
    //errorCopy.dropdown = !state.dropdown
    errorCopy.privacy = !state.privacy

    const errorArr = Object.keys(errorCopy).filter((key) => {
      return errorCopy[key]
    })

    console.log('RegistrationPage > errorCopy = ', errorCopy)

    setErrors(errorCopy)
    return errorArr.length !== 0
  }

  const submit = () => {
    const hasErrors = validateForm()

    if (hasErrors) {
      console.log('RegistrationPage > hasErrors = ', hasErrors)
      return
    }

    const projectRef = DEBUG ? DEBUG_PROJECTREF : PROJECT_REF

    const obj = { ...state, questionniare: questionniareSeleted, campaignId: projectRef }

    post(ENDPOINTS.USER.POST, obj)
      .then((result) => {
        console.log('RegistrationPage > result = ', result)

        goToPage(state)
        return result
      })
      .catch((error) => {
        console.log('RegistrationPage > error = ', error)
        alert('Something went wrong')
        //return Promise.reject(error)
      })
  }

  const closeDialog = () => {
    setShowModal(false)
  }

  const onDropdownSelect = (value) => {
    setErrors({ ...error, dropdown: false })
    setDropDownSeleted(value)
    setState({ ...state, dropdown: value })
  }

  const htmlPart = '<p>' + data?.registration?.terms + '</p>'

  return (
    <>
      <Dialog visible={showModal} animation="zoom" maskAnimation="fade" onClose={closeDialog} title="">
        <div className="container-fluid mb-4 ">
          <div className="row" style={{}}>
            <div className="col-12">
              <h4 className="mt-4 mb-4">{data?.registration?.termsHeadline}</h4>

              <div
                className="p-small"
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{ __html: htmlPart }}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <div className="row my-5 px-5">
        <div className="col-12 col-lg-8 mx-auto registration w-100">
          <div className="w-100">
            <input
              className="py-2 px-2 w-100 text-white form-control rounded-2 "
              style={{
                background: 'rgba(255,255,255,.3)',
                fontSize: fontSize,
                boxShadow: error.name ? 'none' : filedShadow,
                outline: 'none',
                border: error.name ? `2px solid ${errorColor}` : 'none',
              }}
              placeholder={data.registration.formFirstName}
              type="text"
              name="name"
              value={state.name}
              onChange={onChange}
            />
            <ErrorMsg key="name" visible={error.name} msg={registration.errorFormFirstName} />
          </div>
          <div className="">
            <input
              className="py-2 px-2 w-100 text-white form-control rounded-2 mt-3 "
              style={{
                background: 'rgba(255,255,255,.3)',
                fontSize: fontSize,
                boxShadow: error.lastname ? 'none' : filedShadow,
                outline: 'none',
                border: error.lastname ? `2px solid ${errorColor}` : 'none',
              }}
              placeholder={data.registration.formLastName}
              type="text"
              name="lastname"
              value={state.lastname}
              onChange={onChange}
            />
            <ErrorMsg key="lastname" visible={error.lastname} msg={registration.errorFormLastName} />
          </div>
          <div className="">
            <input
              className="py-2 px-2 w-100 text-white form-control rounded-2 mt-3 "
              style={{
                background: 'rgba(255,255,255,.3)',
                fontSize: fontSize,
                boxShadow: error.email ? 'none' : filedShadow,
                outline: 'none',
                border: error.email ? `2px solid ${errorColor}` : 'none',
              }}
              placeholder={data.registration.formEmail}
              type="text"
              name="email"
              value={state.email}
              onChange={onChange}
            />

            <ErrorMsg key="email" visible={error.email} msg={registration.errorFormEmail} />
          </div>
          {usePhoneForm ? (
            <div className="">
              <input
                className="py-2 px-2 w-100 text-white form-control rounded-2 mt-3 "
                style={{
                  background: 'rgba(255,255,255,.3)',
                  fontSize: fontSize,
                  boxShadow: error.email ? 'none' : filedShadow,
                  outline: 'none',
                  border: error.phone ? `2px solid ${errorColor}` : 'none',
                }}
                placeholder={data.registration.formPhone}
                type="number"
                name="phone"
                value={state.phone}
                onChange={onChange}
              />

              <ErrorMsg key="email" visible={error.phone} msg={registration.errorFormPhone} />
            </div>
          ) : null}

          <div className="mt-3">
            {questionniare && questionniare.length > 0 ? (
              questionniare.map((question, index) => {
                return (
                  <QuestionDropdown
                    key={index}
                    data={question}
                    index={index}
                    error={error.questionniare}
                    selected={questionniareSeleted}
                    onSelect={setQuestionniareProxy}
                  ></QuestionDropdown>
                )
              })
            ) : (
              <Dropdown
                data={data}
                error={error.dropdown}
                selected={dropDownSeleted}
                onSelect={onDropdownSelect}
                errorMsg={registration.errorDropDown}
              ></Dropdown>
            )}
          </div>
          <div className="mt-6">
            <Checkbox
              label={data.registration.checkBoxOne}
              setShowModal={setShowModal}
              urlLink=""
              formKey="terms"
              setForm={setState}
              formObj={state}
              error={error.terms}
              errorMsg={registration.errorCheckbox}
            ></Checkbox>
          </div>
          <div className="mt-3">
            <Checkbox
              label={data.registration.checkBoxTwo}
              urlLink={data.registration.checkBoxTwoLink}
              setShowModal=""
              formKey="privacy"
              setForm={setState}
              formObj={state}
              error={error.privacy}
              errorMsg={registration.errorCheckboxTwo}
            ></Checkbox>
          </div>

          <div className="d-flex justify-content-center mt-5">
            <Button onClick={submit} style={{ width: '100%' }} label={data.registration.submitBtn}></Button>
          </div>
        </div>
      </div>
    </>
  )
}

const ErrorMsg = ({ visible, msg, className = 'p-small ' }) => {
  return (
    <>
      {visible ? (
        <p className={className} style={{ color: errorColor }}>
          {msg}
        </p>
      ) : null}
    </>
  )
}

type QuestionDropdownType = {
  data: QuestionData
  selected: string[]
  onSelect: (value: string[]) => void
  error: any
  index: number
}

type QuestionData = {
  title: string
  placeholder: string
  warnTxt: string
  options: QOptions

  _id: string
}

type QOptions = { title: string; _id: string }[]

const QuestionDropdown = ({ data, selected, onSelect, error, index }: QuestionDropdownType) => {
  const [localError, setLocalError] = useState(false)

  console.log('RegistrationPage > selected = ', selected)
  const errorMsg = data?.warnTxt || 'Error'

  const handleSelect = (e) => {
    const selectedIndex: number = parseInt(e.target.value)

    if (selectedIndex === 0) {
      const sCopy = [...selected]
      sCopy[0] = ''
      onSelect(sCopy)

      return
    }

    //setLocalError(false)
    // ---------
    // Since we add the placeholder to the options array, we need to adjust the index
    // ---------
    const answerIndex: number = selectedIndex - 1
    const selectedId = data.options ? data.options[answerIndex] : { _id: '' }

    const selectedCopy = [...selected]
    selectedCopy[index] = selectedId._id
    onSelect(selectedCopy)
  }

  //const options = data.options
  const options: QOptions = [{ title: data.placeholder, _id: 'it the placehoder' }].concat(data.options)

  //
  // Error handling
  // Check if the question has been answered
  //
  useEffect(() => {
    if (error) {
      const missingOptions = data.options.map((option, index) => {
        return index
      })

      setLocalError(missingOptions.length > 0)
    }

    if (!error && localError) {
      setLocalError(false)
    }
  }, [error, localError, setLocalError, data.options])

  console.log('RegistrationPage > localError = ', localError)

  return (
    <>
      <div className="rounded-top w-75">
        <p className="py-2 p-small ">{data.title}</p>
      </div>
      <div
        className="py-2 px-2 w-100 text-white form-control mb-3 position-relative rounded-2"
        style={{
          zIndex: 10,
          marginTop: 0,
          background: 'rgba(255,255,255,.45)',
          fontSize: fontSize,
          boxShadow: error ? 'none' : filedShadow,
          outline: 'none',
          border: localError ? `2px solid ${errorColor}` : 'none',
        }}
      >
        <select
          name="select"
          className="w-100 text-white form-control py-1  "
          onChange={handleSelect}
          style={{
            background: 'transparent',
            fontSize: fontSize,
            outline: 'none',
            border: error.email ? `2px solid ${errorColor}` : 'none',
          }}
        >
          {options.map((option, index) => {
            return (
              <option key={index} value={index} className="text-black">
                {option.title}
              </option>
            )
          })}
        </select>
      </div>
      <div className="">
        <ErrorMsg visible={localError} className="p-small " msg={errorMsg} />
      </div>
    </>
  )
}

const Dropdown = ({ data, selected, onSelect, error, errorMsg }) => {
  const optionsTitle = data.registration?.dropDownTitle || 'missing'
  const optionsOne = data.registration?.dropDownOptionOne || 'missing'
  const optionsTwo = data.registration?.dropDownOptionTwo || 'missing'
  const optionsThree = data.registration?.dropDownOptionThree || 'missing'
  const optionsFour = data.registration?.dropDownOptionFour || 'missing'
  const optionsFive = data.registration?.dropDownOptionFive || 'missing'
  const optionsSix = data.registration?.dropDownOptionSix || 'missing'

  const handleSelect = (e) => {
    const isPlaceHolder = e.target.value === optionsTitle ? true : false
    if (isPlaceHolder) {
      return
    }
    const value = e.target.value || ''

    onSelect(value.trim())
  }

  const options = [optionsTitle, optionsOne, optionsTwo, optionsThree, optionsFour, optionsFive, optionsSix]
  const num = options.filter((item) => (item === 'missing' ? false : true))

  return (
    <>
      <div
        className="py-2 px-2 w-100 text-white form-control rounded-2  "
        style={{
          background: 'rgba(255,255,255,.3)',
          fontSize: fontSize,
          boxShadow: error ? 'none' : filedShadow,
          outline: 'none',
          border: error ? `2px solid ${errorColor}` : 'none',
        }}
      >
        <select
          name="select"
          placeholder="asdasd"
          className=" w-100 text-white form-control rounded-2  "
          onChange={handleSelect}
          value={selected}
          style={{
            background: 'transparent',
            fontSize: fontSize,
            outline: 'none',
            border: error.email ? `2px solid ${errorColor}` : 'none',
          }}
        >
          {num.map((n, index) => {
            return (
              <option key={index} value={n} className="text-black">
                {n}
              </option>
            )
          })}
        </select>
      </div>
      <div className="">
        <ErrorMsg visible={error} msg={errorMsg} />
      </div>
    </>
  )
}

/**
 *
 * CHECK BOXES
 *
 */

const Checkbox = ({ label = 'missing', setForm, formKey, formObj, error, urlLink, setShowModal, errorMsg = '' }) => {
  const clickker = () => {
    setForm({ ...formObj, [formKey]: !formObj[formKey] })
  }

  const selected = formObj[formKey] as boolean

  const labelLinkFirst: number = label.indexOf('{{')
  const labelLinkSecond: number = label.indexOf('}}')

  const first = labelLinkFirst > -1 ? label.slice(0, labelLinkFirst) : ''
  const second = labelLinkSecond > -1 ? label.slice(labelLinkSecond + 2) : ''
  const linkText = first ? label.slice(labelLinkFirst + 2, labelLinkSecond) : label

  return (
    <>
      <div className="d-flex pointer">
        <div className="position-relative">
          {selected ? (
            <CheckMark
              className="noselect position-absolute mt-1 "
              style={{ marginLeft: 5, top: 0, left: 0, pointerEvents: 'none' }}
            ></CheckMark>
          ) : null}

          <div
            onClick={clickker}
            className="rounded-2"
            style={{
              background: 'rgba(255,255,255,.3)',
              boxShadow: error ? 'none' : '0px 0px 0px 1px rgba(255, 255, 255, .9)',
              outline: 'none',
              border: error ? `2px solid ${errorColor}` : 'none',
              width: 24,
              height: 24,
            }}
          ></div>
        </div>

        {urlLink ? (
          <p className="ms-3 p-small">
            <span>{first}</span>
            <span className="fw-bold">
              <a
                className="text-decoration-none text-decoration-underline text-white"
                href={urlLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {linkText}
              </a>
            </span>
            <span>{second}</span>
          </p>
        ) : (
          <p
            className="ms-3 p-small"
            onClick={() => {
              setShowModal(true)
            }}
          >
            <span>{first}</span>
            <span className="fw-bold text-decoration-underline">{linkText}</span>
            <span>{second}</span>
          </p>
        )}
      </div>

      <div className="ms-6">
        <ErrorMsg visible={error} msg={errorMsg} />
      </div>
    </>
  )
}
