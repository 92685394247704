/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 17/08/2023.
 */
import React from 'react'
import { configStore } from '../store/configStore'

type Props = { label: string; onClick?: () => void; style?: React.CSSProperties }

export const Button = ({ label, onClick, style = {} }: Props) => {
  const primaryColor = configStore((state) => state.config?.wheel?.primaryColor)
  const bgColor = primaryColor ? primaryColor : 'blue'

  const clikker = () => {
    if (onClick) {
      onClick()
    } else {
      console.log('Button > no click handler = ')
    }
  }

  return (
    <button
      onClick={clikker}
      className="btn btn-primary py-3 py-md-3 px-6 px-md-7 mt-md-6 fw-bold"
      style={{ border: 'none', background: bgColor, ...style }}
    >
      <p>{label}</p>
    </button>
  )
}

export const FooterButton = ({ label, onClick, style = {} }: Props) => {
  const primaryColor = configStore((state) => state.config?.wheel?.primaryColor)
  const bgColor = primaryColor ? primaryColor : 'blue'

  const clikker = () => {
    if (onClick) {
      onClick()
    } else {
      console.log('Button > no click handler = ')
    }
  }

  return (
    <button
      onClick={clikker}
      className="btn btn-primary rounded-pill py-2 py-md-2 px-7 px-md-7 mt-md-4 fw-bold"
      style={{ border: 'none', background: bgColor, ...style }}
    >
      <p className="p-small px-2 py-1">{label}</p>
    </button>
  )
}
